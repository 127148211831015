/* @import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,500&display=swap"); */

.responsive-carousel {
  width: 100%;
  max-width: 400px;
  max-height: 512px;
}

.carousel {
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
  height: 420px;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 1.5rem;
  z-index: 10;
  padding-bottom: 0.25em !important;
}

.card-img-top {
  position: relative;
  height: 80%;
  object-fit: cover;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  /* height: 32rem; */
  background-color: #777;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}
@supports (object-fit: cover) {
  .carousel-item > img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

h3,
h4 {
  color: black;
}

.carousel-indicators > li {
  background-color:goldenrod !important;
}

@media (max-width: 400px) {
  .carousel-inner {
    height: 21em;
  }
  .carousel {
    min-height: 27em;
  }
  .card {
    height: 22em;
  }
  .card > img {
    height: 10em;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  .carousel-inner {
    height: 23em;
  }
  .carousel {
    min-height: 27em;
  }
  .card {
    height: 24em;
  }
  .card > img {
    height: 15em;
  }
}

@media (min-width: 500px) and (max-width: 1024px) {
  .carousel-inner {
    height: 32em;
  }
  .carousel {
    min-height: 35em;
  }
  .card {
    height: 33em;
  }
  .card > img {
    height: 25em;
  }
}

@media (min-width: 1024px) and (max-width: 1524px) {
  .carousel-inner {
    height: 33em;
  }
  .carousel {
    min-height: 36em;
  }
  .card {
    height: 34em;
  }
  .card > img {
    height: 27em;
  }
}

@media (min-width: 1524px) and (max-width: 2560px) {
  .carousel-inner {
    height: 70em;
  }
  .carousel {
    min-height: 67em;
  }
  .card {
    height: 60em;
  }
  .card > img {
    height: 60em;
  }
}

@media (min-width: 2560px) {
  .carousel-inner {
    height: 70em;
  }
  .carousel {
    min-height: 67em;
  }
  .card {
    height: 60em;
  }
  .card > img {
    height: 60em;
  }
}

/* @media (min-width: 1280px) {
  .carousel-inner {
    height: 45em;
  }
  .carousel {
    margin-bottom: 2rem;
    font-family: "Playfair Display", serif;
    min-height: 45em;
  }
  .card {
    height: 700px;
  }
}

@media (min-width: 1024px) {
  .carousel-inner {
    height: 45em;
  }
  .carousel {
    margin-bottom: 2rem;
    font-family: "Playfair Display", serif;
    min-height: 45em;
  }
  .card {
    height: 700px;
  }
}

@media (max-width: 720px) {
  .carousel-inner {
    height: 45em;
  }
  .carousel {
    margin-bottom: 2rem;
    font-family: "Playfair Display", serif;
    min-height: 47em;
  }
  .card {
    height: 700px;
  }
}

@media (max-height: 350px) {
  .carousel-inner {
    height: 630px;
  }
  .carousel {
    height: 630px;
  }
  .card {
    height: 600px;
  }
}

@media (min-width: 2000px) {
  .carousel-inner {
    height: 70em;
  }
  .carousel {
    height: 70em;
  }
  .card {
    height: 1200px;
  } */
/* } */
