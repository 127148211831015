.search-container {
  padding-top: 2%;
  padding-bottom: 2%;
}
.col-container-search,
.col-container-search-small {
  background-color: rgb(190, 195, 200);
}

.container-result {
  height: 1230px;
  overflow-x: auto;
  overflow-y: hidden;
}

.container-result .row-estate {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.filer-button {
  display: none !important;
}

.filer-button {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
}

.filer-button:hover {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8);
}

.filer-button:focus,
.filer-button:active {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.button-right {
  padding-right: 2em !important;
}

.summary {
  padding-top: 1em;
  padding-bottom: 1em;
}

.summary .row:not(.description) {
  font-weight: bold;
}

.aling-end-row {
  justify-content: flex-end;
}

.aling-end-row > .btn-secondary {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
}

.aling-end-row > .btn-secondary:hover {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8);
}

.aling-end-row > .btn-secondary:focus,
.aling-end-row > .btn-secondary:active {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.container-result .image-gallery-content:not(.fullscreen) .image-gallery-image {
  height: 30vh;
}

.container-result .image-gallery-left-nav .image-gallery-svg,
.container-result .image-gallery-right-nav .image-gallery-svg {
  height: 30px;
  width: 30px;
}

.container-result .image-gallery-play-button,
.container-result .image-gallery-fullscreen-button {
  display: none;
}

.row-pagination {
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
}

.row-button-filter {
  justify-content: flex-end;
  padding: 0.5em;
}

.end-row {
  text-align: center;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.end-row .btn-outline-info {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
}
.end-row .btn-outline-info:hover {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8);
}
.end-row .btn-outline-info:active,
.end-row .btn-outline-info:focus {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.loading {
  display: flex !important;
  text-align: center;
}

@media (max-width: 991px) {
  .filer-button {
    display: block !important;
  }
  .col-container-search {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .col-container-search-small {
    display: none !important;
  }
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 0.2em !important;
}

.infinite-scroll-component::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
}
.end-row a {
  color: black;
}

.end-row a:link {
  color: black;
}

.end-row a:visited {
  color: black;
}
.end-row a:hover {
  text-decoration: none;
}
