.fondo-cookies {
  background-size: contain;
  height: 30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 !important;
}
.imagen-cookies {
  height: 20vh;
  margin-top: 1em;
  z-index: 1;
  position: absolute;
}
.container-background-cookies {
  background-color: rgba(180, 179, 179, 0.452);
  color: white;
  height: 25vh !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
}
.container-cookies {
  padding-bottom: 2em;
  text-align: justify;
}
.lista-cookies {
  list-style: none;
  padding: 0;
  overflow-wrap: break-word;
}
.lista-cookies > li {
  padding-left: 3em;
  padding-top: 0.5em;
}
.lista-cookies > li:before {
  content: "\02743"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
  color: goldenrod;
}
