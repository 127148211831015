header > nav {
  padding: 0 !important;
  padding-bottom: 1em !important;
  align-items: center !important;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2);
}

.navbar-nav {
  padding-top: 3em;
}

.container-item-nav {
  padding-bottom: 1em;
  border-bottom: 3px solid rgba(0, 0, 90, 0);
  transition: border-bottom 0.6s linear;
  /* transition: box-shadow 0; */
}

#basic-navbar-nav a h5 {
  border-right: 0.2em solid rgba(0, 0, 92, 0.1);
  padding-right: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.container-item-nav:hover {
  border-bottom: 3px solid goldenrod;
  /* box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2);
  border-radius: 15%; */
}

.navbar-nav {
  margin-left: 3em;
  min-width: 100%;
}

.container-translate {
  display: flex !important;
  justify-content: flex-end !important;
  overflow: hidden;
  padding-right: 1em;
}

@media (max-width: 1024px), handheld and (orientation: landscape) {
  .navbar-nav {
    margin-left: 0;
    min-width: 100%;
  }
}

@media (max-width: 1280px), handheld and (orientation: landscape) {
  .navbar-nav {
    margin-left: 0;
    min-width: 100%;
  }
}
