@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

html,
body {
  min-height: 100% !important;
  height: 100%;
}
body {
  margin: 0;
  font-family: "Source Sans Pro", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: "Source Sans Pro", sans-serif;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; */
}
