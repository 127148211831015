.cointainer-images {
  overflow: auto;
  align-items: center;
  text-align: center;
  margin-top: 1%;
  max-height: 18em;
}

.cointainer-images .row-images {
  margin-top: 1em;
}

.miniature {
  padding-bottom: 1em;
}

.cointainer-images .miniature {
  background-color: rgba(128, 128, 128, 0.2);
  margin-top: 1em;
}

.cointainer-images .miniature > figure {
  margin-top: 1em;
}

.row-miniature {
  padding-left: 1em;
  padding-right: 1em;
}

.row-miniature > div {
  width: 100%;
}

.row-miniature > div > label {
  margin-left: 5%;
  margin-right: 5%;
}

.custom-switch > input {
  width: 100%;
  padding-left: 0 !important;
  z-index: 5;
}

.picture-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
}

.container-video {
  float: none;
  margin: 0 auto;
}

.container-video-miniature {
  min-height: 190px;
  align-items: center;
  display: flex;
}

.row-trash {
  margin-bottom: -5%;
}

.col-trash {
  padding-right: 1em !important;
  margin-top: 2%;
}
