.banner-info {
  position: relative;
  z-index: 5;
  color: white;
  padding: 2em;
}

.banner-img {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  padding-left: 1em;
  padding-right: 1em;
  /* background: url("https://harunnaapp89ba092d890f4a839722aea67392711c140000-dev.s3-eu-west-1.amazonaws.com/entrepreneur_1280.jpg")
    no-repeat center center; */
  /* opacity: 0.6; */
}

.banner-massage {
  max-width: 1145px;
  width: calc(100% - 60px);
  margin: 0 auto;
}

.list-group-banner {
  background-color: black;
  margin-top: 2em;
}
.list-group-banner > div {
  background-color: black;
  border-color: aliceblue;
}

.list-group-banner a {
  color: white;
}

.list-group-banner a:link {
  color: white;
}

.list-group-banner a:visited {
  color: white;
}

.linea {
  border-top: 2px solid rgb(123 109 24);
  height: 2px;
  padding: 0;
  margin: 2% auto 0 auto;
  width: 4em;
  float: left;
}
.texto-group-banner {
  padding-top: 2em;
}
