.guest-footer {
  background-color: rgba(23, 39, 215, 0.12);
  display: flex;
  align-items: center;
}

.guest-footer > div {
  padding-top: 1em;
  padding-bottom: 1em;
}
