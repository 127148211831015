.image-about {
  background: url("../img/about-girl.jpg") no-repeat center center;
  background-size: contain;
  text-align: center;
  /* max-height: auto; */
  height: 40vh !important;
}

.container-background {
  /* background-color: rgba(112, 112, 112, 0.7); */
  color: white;
  height: 40vh !important;
  justify-content: center;
  align-items: center;
}

.about-message {
  margin-bottom: 0 !important;
  text-align: center;
  background-color: #f5f5eb !important;
}

.aling-rigth {
  text-align: right;
}
.textoSobreNosotros {
  margin-top: 4%;
}
