.rows-admin-list {
  margin-top: 2%;
  margin-bottom: 2%;
}

.span-error-app {
  min-height: 24px;
}

.border-form-house {
  border: groove;
  padding-top: 1em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.preview-main-form {
  max-height: 10em;
}

.container-switch,
.container-highlighted {
  padding-left: 2em !important;
}

.container-raadio{
  padding-left: 0.5em !important;
}

.inputs-flat{
  display: flex;
}
