.container-search {
  padding-bottom: 2em;
  padding-top: 2em;
}

.boton-filtros {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  margin-top: 1em;
}
.boton-filtros:hover,
.boton-filtros:focus {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.boton-filtros:not(:disabled):not(.disabled):active {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}
