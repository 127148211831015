@import "~react-image-gallery/styles/css/image-gallery.css";

.container-images {
  height: 55vh;
}

.image-gallery-swipe {
  height: 100%;
}

.image-gallery-content:not(.fullscreen) .image-gallery-image {
  width: 100%;
  height: 50vh;
  position: relative;
}

.first-info {
  display: flex;
}

.first-info > div {
  margin: 1%;
  padding-right: 2%;
  border-right: 1px solid;
}

.spinner-images {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.details-info > .row {
  margin-top: 1em;
}

.paragraph-highlighted-text {
  font-weight: bold;
  font-style: italic;
}

.reference {
  color: darkgrey;
}

.container-images .image-gallery-left-nav .image-gallery-svg,
.container-images .image-gallery-right-nav .image-gallery-svg {
  height: 30px;
  width: 30px;
}

.container-images .image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg .container-images .image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 30px;
  width: 30px;
}

@media (max-width: 770px) {
  .form-contact {
    display: none !important;
  }
  .fixed-bottom {
    display: block !important;
  }
}

.fullscreen > .image-gallery-slide-wrapper > .image-gallery-swipe > .image-gallery-slides > .center > .container-video {
  height: 100vh !important;
  align-items: center;
}

.container-video {
  display: flex;
  justify-content: center;
}

.row-map {
  min-height: 30vh;
}

.texto-destacados > .btn-secondary {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
}
.boton-listado {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
}
.boton-listado:hover,
.boton-listado:focus {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.boton-listado:not(:disabled):not(.disabled):active {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}
.info-estados > button {
  margin-top: 0.5em;
}
