.fondo-aviso-legal {
  /* background: url("../../img/avisoLegal.png") no-repeat center center; */
  background-size: contain;
  height: 30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 !important;
}
.imagen-aviso-legal {
  /* background: url("../../img/avisoLegal.png") no-repeat center center; */
  height: 20vh;
  margin-top: 1em;
  z-index: 1;
  position: absolute;
}
.container-background-aviso-legal {
  background-color: rgba(180, 179, 179, 0.452);
  color: white;
  height: 25vh !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
}
.container-aviso-legal {
  padding-bottom: 2em;
  text-align: justify;
}
.lista-aviso-legal {
  list-style: none;
  padding: 0;
  overflow-wrap: break-word;
}
.lista-aviso-legal > li {
  padding-left: 3em;
  padding-top: 0.5em;
}
.lista-aviso-legal > li:before {
  content: "\02743"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
  color: goldenrod;
}
