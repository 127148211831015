.image-contacto {
  background: url("../img/contacto-nosotros.jpg") no-repeat right center;
  background-size: cover;
  height: 70vh;
  width: 100%;
}

.contact-title {
  padding-bottom: 1em;
  height: 54px;
}

.contact-info {
  padding: 1em;
}

.row-map {
  padding-top: 1em;
  padding-bottom: 2em;
}

.linea-contacto {
  border-top: 2px solid #dabb68;
  height: 2px;
  padding: 0;
  margin: 2% auto 0 auto;
  width: 10em;
  float: left;
}

.contact-page-info-item {
  background-color: rgba(0, 0, 0, 0) !important;
}

.contact-page-info-item a {
  color: black;
}

.contact-page-info-item a:link {
  color: black;
}

.contact-page-info-item a:visited {
  color: rgb(68, 67, 67);
}
.boton-contacto {
  color: #fff;
  background-color: #dabb68 !important;
  border-color: #dabb68 !important;
  color: black !important;
  font-weight: bold !important;
}

.boton-contacto:focus {
  color: #fff;
  background-color: #dabb68 !important;
  border-color: #dabb68 !important;
  box-shadow: 0 0 0 0.2rem rgba(2, 12, 14, 0.5) !important;
  color: black !important;
  font-weight: bold !important;
}

.container-sended-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.container-sended-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
