.App {
  text-align: center;
  height: 100%;
}
#root {
  height: -webkit-fill-available;
}

::-webkit-scrollbar {
  width: 0.5em !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
