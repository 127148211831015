@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
html,
body {
  min-height: 100% !important;
  height: 100%;
}
body {
  margin: 0;
  font-family: "Source Sans Pro", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: "Source Sans Pro", sans-serif;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; */
}

.App {
  text-align: center;
  height: 100%;
}
#root {
  height: -webkit-fill-available;
}

::-webkit-scrollbar {
  width: 0.5em !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

#img-background {
  position: fixed;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background: url("https://harunnaapp89ba092d890f4a839722aea67392711c140000-dev.s3-eu-west-1.amazonaws.com/2929219-min.jpg")
    no-repeat center center;
  background-size: cover;
}
@media only screen and (max-width: 600px) {
  div#img-background {
    width: 700px !important;
    height: 900px !important;
  }
}

.padding-login {
  padding: 0 !important;
}

#errorRow {
  min-height: 89px;
  margin-top: 2%;
  -webkit-animation: mymove 5s;
          animation: mymove 5s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-name: show-soft;
          animation-name: show-soft;
}

#divLogo {
  min-height: 140px;
  min-width: 230px;
  background-image: url("https://harunnaapp89ba092d890f4a839722aea67392711c140000-dev.s3-eu-west-1.amazonaws.com/harruna-logo-fondo-blanco.jpeg");
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  margin: 10px;
}

@-webkit-keyframes show-soft {
  from {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes show-soft {
  from {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

.loginForm {
  background-color: rgba(70, 58, 91, 0.2);
  border: solid;
  padding: 15px;
  border-radius: 25px;
  border-color: rgba(103, 58, 91, 0.1);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button-cancel-margin {
  margin-top: 6%;
}

.button-right {
  padding-right: 2em !important;
}
.button-left {
  padding-right: 2em !important;
}

.nav-home {
  padding-top: 0 !important;
}

.dropdown > a {
  color: white;
}

.dropdown > a:hover {
  color: rgb(200, 200, 200);
}

.App {
  text-align: center;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.rows-admin-list {
  margin-top: 2%;
  margin-bottom: 2%;
}

.span-error-app {
  min-height: 24px;
}

.border-form-house {
  border: groove;
  padding-top: 1em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.preview-main-form {
  max-height: 10em;
}

.container-switch,
.container-highlighted {
  padding-left: 2em !important;
}

.container-raadio{
  padding-left: 0.5em !important;
}

.inputs-flat{
  display: flex;
}

.cointainer-images {
  overflow: auto;
  align-items: center;
  text-align: center;
  margin-top: 1%;
  max-height: 18em;
}

.cointainer-images .row-images {
  margin-top: 1em;
}

.miniature {
  padding-bottom: 1em;
}

.cointainer-images .miniature {
  background-color: rgba(128, 128, 128, 0.2);
  margin-top: 1em;
}

.cointainer-images .miniature > figure {
  margin-top: 1em;
}

.row-miniature {
  padding-left: 1em;
  padding-right: 1em;
}

.row-miniature > div {
  width: 100%;
}

.row-miniature > div > label {
  margin-left: 5%;
  margin-right: 5%;
}

.custom-switch > input {
  width: 100%;
  padding-left: 0 !important;
  z-index: 5;
}

.picture-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
}

.container-video {
  float: none;
  margin: 0 auto;
}

.container-video-miniature {
  min-height: 190px;
  align-items: center;
  display: flex;
}

.row-trash {
  margin-bottom: -5%;
}

.col-trash {
  padding-right: 1em !important;
  margin-top: 2%;
}


.body-modal-pictures {
    margin: auto;
  }
.container-progress {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 100%;
  padding-top: 20%;
  background-color: rgba(0, 0, 0, 0.3);
}

.container-spinner {
  position: fixed;
  z-index: 11;
}

.col-spinner {
  position: fixed;
  display: flex;
  flex-direction: column;
}

.container-notifications {
  position: absolute;
  top: 1%;
  right: 1%;
  z-index: 1;
}

.rows-admin-list {
  margin-top: 2%;
  margin-bottom: 2%;
}

.rows-admin-list-2 {
  /* height: 500px; */
  overflow-x: scroll;
}




header > nav {
  padding: 0 !important;
  padding-bottom: 1em !important;
  align-items: center !important;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2);
}

.navbar-nav {
  padding-top: 3em;
}

.container-item-nav {
  padding-bottom: 1em;
  border-bottom: 3px solid rgba(0, 0, 90, 0);
  transition: border-bottom 0.6s linear;
  /* transition: box-shadow 0; */
}

#basic-navbar-nav a h5 {
  border-right: 0.2em solid rgba(0, 0, 92, 0.1);
  padding-right: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.container-item-nav:hover {
  border-bottom: 3px solid goldenrod;
  /* box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2);
  border-radius: 15%; */
}

.navbar-nav {
  margin-left: 3em;
  min-width: 100%;
}

.container-translate {
  display: flex !important;
  justify-content: flex-end !important;
  overflow: hidden;
  padding-right: 1em;
}

@media (max-width: 1024px), handheld and (orientation: landscape) {
  .navbar-nav {
    margin-left: 0;
    min-width: 100%;
  }
}

@media (max-width: 1280px), handheld and (orientation: landscape) {
  .navbar-nav {
    margin-left: 0;
    min-width: 100%;
  }
}

/* @import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,500&display=swap"); */

.responsive-carousel {
  width: 100%;
  max-width: 400px;
  max-height: 512px;
}

.carousel {
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
  height: 420px;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 1.5rem;
  z-index: 10;
  padding-bottom: 0.25em !important;
}

.card-img-top {
  position: relative;
  height: 80%;
  object-fit: cover;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  /* height: 32rem; */
  background-color: #777;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}
@supports (object-fit: cover) {
  .carousel-item > img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

h3,
h4 {
  color: black;
}

.carousel-indicators > li {
  background-color:goldenrod !important;
}

@media (max-width: 400px) {
  .carousel-inner {
    height: 21em;
  }
  .carousel {
    min-height: 27em;
  }
  .card {
    height: 22em;
  }
  .card > img {
    height: 10em;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  .carousel-inner {
    height: 23em;
  }
  .carousel {
    min-height: 27em;
  }
  .card {
    height: 24em;
  }
  .card > img {
    height: 15em;
  }
}

@media (min-width: 500px) and (max-width: 1024px) {
  .carousel-inner {
    height: 32em;
  }
  .carousel {
    min-height: 35em;
  }
  .card {
    height: 33em;
  }
  .card > img {
    height: 25em;
  }
}

@media (min-width: 1024px) and (max-width: 1524px) {
  .carousel-inner {
    height: 33em;
  }
  .carousel {
    min-height: 36em;
  }
  .card {
    height: 34em;
  }
  .card > img {
    height: 27em;
  }
}

@media (min-width: 1524px) and (max-width: 2560px) {
  .carousel-inner {
    height: 70em;
  }
  .carousel {
    min-height: 67em;
  }
  .card {
    height: 60em;
  }
  .card > img {
    height: 60em;
  }
}

@media (min-width: 2560px) {
  .carousel-inner {
    height: 70em;
  }
  .carousel {
    min-height: 67em;
  }
  .card {
    height: 60em;
  }
  .card > img {
    height: 60em;
  }
}

/* @media (min-width: 1280px) {
  .carousel-inner {
    height: 45em;
  }
  .carousel {
    margin-bottom: 2rem;
    font-family: "Playfair Display", serif;
    min-height: 45em;
  }
  .card {
    height: 700px;
  }
}

@media (min-width: 1024px) {
  .carousel-inner {
    height: 45em;
  }
  .carousel {
    margin-bottom: 2rem;
    font-family: "Playfair Display", serif;
    min-height: 45em;
  }
  .card {
    height: 700px;
  }
}

@media (max-width: 720px) {
  .carousel-inner {
    height: 45em;
  }
  .carousel {
    margin-bottom: 2rem;
    font-family: "Playfair Display", serif;
    min-height: 47em;
  }
  .card {
    height: 700px;
  }
}

@media (max-height: 350px) {
  .carousel-inner {
    height: 630px;
  }
  .carousel {
    height: 630px;
  }
  .card {
    height: 600px;
  }
}

@media (min-width: 2000px) {
  .carousel-inner {
    height: 70em;
  }
  .carousel {
    height: 70em;
  }
  .card {
    height: 1200px;
  } */
/* } */

.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.marketing .title {
  margin-bottom: 2em;
  margin-top: 1em;
  /* background-color: rgb(231, 231, 231); */
}

.marketing {
  text-align: center;
}

.img-highlighted {
  height: 235px !important;
}

.App-logo {
  height: 6vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.texto-destacados > .btn-secondary {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
}
.texto-destacados > .btn-secondary:hover {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8);
}
.texto-destacados > .btn-secondary:hover,
.btn-secondary:focus {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.texto-destacados > .btn-secondary:not(:disabled):not(.disabled):active {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8);
}

.guest-footer {
  background-color: rgba(23, 39, 215, 0.12);
  display: flex;
  align-items: center;
}

.guest-footer > div {
  padding-top: 1em;
  padding-bottom: 1em;
}

.banner-info {
  position: relative;
  z-index: 5;
  color: white;
  padding: 2em;
}

.banner-img {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  padding-left: 1em;
  padding-right: 1em;
  /* background: url("https://harunnaapp89ba092d890f4a839722aea67392711c140000-dev.s3-eu-west-1.amazonaws.com/entrepreneur_1280.jpg")
    no-repeat center center; */
  /* opacity: 0.6; */
}

.banner-massage {
  max-width: 1145px;
  width: calc(100% - 60px);
  margin: 0 auto;
}

.list-group-banner {
  background-color: black;
  margin-top: 2em;
}
.list-group-banner > div {
  background-color: black;
  border-color: aliceblue;
}

.list-group-banner a {
  color: white;
}

.list-group-banner a:link {
  color: white;
}

.list-group-banner a:visited {
  color: white;
}

.linea {
  border-top: 2px solid rgb(123 109 24);
  height: 2px;
  padding: 0;
  margin: 2% auto 0 auto;
  width: 4em;
  float: left;
}
.texto-group-banner {
  padding-top: 2em;
}

.image-about {
  background: url(/static/media/about-girl.bf9a1563.jpg) no-repeat center center;
  background-size: contain;
  text-align: center;
  /* max-height: auto; */
  height: 40vh !important;
}

.container-background {
  /* background-color: rgba(112, 112, 112, 0.7); */
  color: white;
  height: 40vh !important;
  justify-content: center;
  align-items: center;
}

.about-message {
  margin-bottom: 0 !important;
  text-align: center;
  background-color: #f5f5eb !important;
}

.aling-rigth {
  text-align: right;
}
.textoSobreNosotros {
  margin-top: 4%;
}

.image {
  background: url(/static/media/services1.8907a8c1.jpg) no-repeat center center;
  background-size: cover;
  height: 40vh;
  width: 100%;
}
.services > li {
  padding-bottom: 2ex !important;
}
.fila-services > div {
  padding-top: 2em;
  padding-bottom: 2em;
}
.body-services {
  padding-top: 0 !important;
}
.container-imagen-services {
  justify-content: center;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media (max-width: 575px) {
  .fila-services .card img {
    height: 10vh !important;
    width: 25vw !important;
  }
}
.fila-services .card img {
  width: 10vw;
}
.fila-services .card {
  height: auto !important;
}
.fila-services .card-footer {
  background-color: #dabb68 !important;
}
.fila-services .card-header {
  background-color: #dabb68 !important;
  font-weight: bold;
  color: white;
}
.letra-services {
  color: white;
}
.fila-services .card-footer .btn-outline-info {
  color: white;
  border: none !important;
}
.fila-services .card-footer .btn-outline-info:hover {
  background-color: black;
  border: none !important;
  box-shadow: 0 0 0 0.2rem rgba(2, 12, 14, 0.5) !important;
}
.fila-services .card-footer .btn-outline-info:active {
  background-color: black !important;
  border: none !important;
}

.fila-services .card-footer .btn-outline-info:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 12, 14, 0.5) !important;
}

.container-images {
  height: 55vh;
}

.image-gallery-swipe {
  height: 100%;
}

.image-gallery-content:not(.fullscreen) .image-gallery-image {
  width: 100%;
  height: 50vh;
  position: relative;
}

.first-info {
  display: flex;
}

.first-info > div {
  margin: 1%;
  padding-right: 2%;
  border-right: 1px solid;
}

.spinner-images {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.details-info > .row {
  margin-top: 1em;
}

.paragraph-highlighted-text {
  font-weight: bold;
  font-style: italic;
}

.reference {
  color: darkgrey;
}

.container-images .image-gallery-left-nav .image-gallery-svg,
.container-images .image-gallery-right-nav .image-gallery-svg {
  height: 30px;
  width: 30px;
}

.container-images .image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg .container-images .image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 30px;
  width: 30px;
}

@media (max-width: 770px) {
  .form-contact {
    display: none !important;
  }
  .fixed-bottom {
    display: block !important;
  }
}

.fullscreen > .image-gallery-slide-wrapper > .image-gallery-swipe > .image-gallery-slides > .center > .container-video {
  height: 100vh !important;
  align-items: center;
}

.container-video {
  display: flex;
  justify-content: center;
}

.row-map {
  min-height: 30vh;
}

.texto-destacados > .btn-secondary {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
}
.boton-listado {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
}
.boton-listado:hover,
.boton-listado:focus {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.boton-listado:not(:disabled):not(.disabled):active {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}
.info-estados > button {
  margin-top: 0.5em;
}

.fixed-bottom {
  display: none !important;
}

@media (max-width: 770px) {
  .fixed-bottom {
    display: block !important;
  }
}

.fondo-aviso-legal {
  /* background: url("../../img/avisoLegal.png") no-repeat center center; */
  background-size: contain;
  height: 30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 !important;
}
.imagen-aviso-legal {
  /* background: url("../../img/avisoLegal.png") no-repeat center center; */
  height: 20vh;
  margin-top: 1em;
  z-index: 1;
  position: absolute;
}
.container-background-aviso-legal {
  background-color: rgba(180, 179, 179, 0.452);
  color: white;
  height: 25vh !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
}
.container-aviso-legal {
  padding-bottom: 2em;
  text-align: justify;
}
.lista-aviso-legal {
  list-style: none;
  padding: 0;
  overflow-wrap: break-word;
}
.lista-aviso-legal > li {
  padding-left: 3em;
  padding-top: 0.5em;
}
.lista-aviso-legal > li:before {
  content: "\02743"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
  color: goldenrod;
}

.fondo-privacidad {
  background-size: contain;
  height: 30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 !important;
}
.imagen-politica-privacidad {
  height: 20vh;
  margin-top: 1em;
  z-index: 1;
  position: absolute;
}
.container-background-privacidad {
  background-color: rgba(180, 179, 179, 0.452);
  color: white;
  height: 25vh !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
}
.container-privacidad {
  padding-bottom: 2em;
  text-align: justify;
}

.lista-privacidad {
  list-style: none;
  padding: 0;
  overflow-wrap: break-word;
}
.lista-privacidad > li {
  padding-left: 3em;
  padding-top: 0.5em;
}
.lista-privacidad > li:before {
  content: "\02743"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
  color: goldenrod;
}

.fondo-cookies {
  background-size: contain;
  height: 30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 !important;
}
.imagen-cookies {
  height: 20vh;
  margin-top: 1em;
  z-index: 1;
  position: absolute;
}
.container-background-cookies {
  background-color: rgba(180, 179, 179, 0.452);
  color: white;
  height: 25vh !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
}
.container-cookies {
  padding-bottom: 2em;
  text-align: justify;
}
.lista-cookies {
  list-style: none;
  padding: 0;
  overflow-wrap: break-word;
}
.lista-cookies > li {
  padding-left: 3em;
  padding-top: 0.5em;
}
.lista-cookies > li:before {
  content: "\02743"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
  color: goldenrod;
}

.search-container {
  padding-top: 2%;
  padding-bottom: 2%;
}
.col-container-search,
.col-container-search-small {
  background-color: rgb(190, 195, 200);
}

.container-result {
  height: 1230px;
  overflow-x: auto;
  overflow-y: hidden;
}

.container-result .row-estate {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.filer-button {
  display: none !important;
}

.filer-button {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
}

.filer-button:hover {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8);
}

.filer-button:focus,
.filer-button:active {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.button-right {
  padding-right: 2em !important;
}

.summary {
  padding-top: 1em;
  padding-bottom: 1em;
}

.summary .row:not(.description) {
  font-weight: bold;
}

.aling-end-row {
  justify-content: flex-end;
}

.aling-end-row > .btn-secondary {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
}

.aling-end-row > .btn-secondary:hover {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8);
}

.aling-end-row > .btn-secondary:focus,
.aling-end-row > .btn-secondary:active {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.container-result .image-gallery-content:not(.fullscreen) .image-gallery-image {
  height: 30vh;
}

.container-result .image-gallery-left-nav .image-gallery-svg,
.container-result .image-gallery-right-nav .image-gallery-svg {
  height: 30px;
  width: 30px;
}

.container-result .image-gallery-play-button,
.container-result .image-gallery-fullscreen-button {
  display: none;
}

.row-pagination {
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
}

.row-button-filter {
  justify-content: flex-end;
  padding: 0.5em;
}

.end-row {
  text-align: center;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.end-row .btn-outline-info {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
}
.end-row .btn-outline-info:hover {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8);
}
.end-row .btn-outline-info:active,
.end-row .btn-outline-info:focus {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.loading {
  display: flex !important;
  text-align: center;
}

@media (max-width: 991px) {
  .filer-button {
    display: block !important;
  }
  .col-container-search {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .col-container-search-small {
    display: none !important;
  }
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 0.2em !important;
}

.infinite-scroll-component::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
}
.end-row a {
  color: black;
}

.end-row a:link {
  color: black;
}

.end-row a:visited {
  color: black;
}
.end-row a:hover {
  text-decoration: none;
}

.container-search {
  padding-bottom: 2em;
  padding-top: 2em;
}

.boton-filtros {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  margin-top: 1em;
}
.boton-filtros:hover,
.boton-filtros:focus {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.boton-filtros:not(:disabled):not(.disabled):active {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.image-contacto {
  background: url(/static/media/contacto-nosotros.a05b213e.jpg) no-repeat right center;
  background-size: cover;
  height: 70vh;
  width: 100%;
}

.contact-title {
  padding-bottom: 1em;
  height: 54px;
}

.contact-info {
  padding: 1em;
}

.row-map {
  padding-top: 1em;
  padding-bottom: 2em;
}

.linea-contacto {
  border-top: 2px solid #dabb68;
  height: 2px;
  padding: 0;
  margin: 2% auto 0 auto;
  width: 10em;
  float: left;
}

.contact-page-info-item {
  background-color: rgba(0, 0, 0, 0) !important;
}

.contact-page-info-item a {
  color: black;
}

.contact-page-info-item a:link {
  color: black;
}

.contact-page-info-item a:visited {
  color: rgb(68, 67, 67);
}
.boton-contacto {
  color: #fff;
  background-color: #dabb68 !important;
  border-color: #dabb68 !important;
  color: black !important;
  font-weight: bold !important;
}

.boton-contacto:focus {
  color: #fff;
  background-color: #dabb68 !important;
  border-color: #dabb68 !important;
  box-shadow: 0 0 0 0.2rem rgba(2, 12, 14, 0.5) !important;
  color: black !important;
  font-weight: bold !important;
}

.container-sended-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.container-sended-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-404 {
  background: url(/static/media/page-404.41b0fac5.jpg) no-repeat center center;
  background-size: contain;
  height: 70vh;
  width: 100%;
}

