.container-progress {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 100%;
  padding-top: 20%;
  background-color: rgba(0, 0, 0, 0.3);
}

.container-spinner {
  position: fixed;
  z-index: 11;
}

.col-spinner {
  position: fixed;
  display: flex;
  flex-direction: column;
}
