#img-background {
  position: fixed;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background: url("https://harunnaapp89ba092d890f4a839722aea67392711c140000-dev.s3-eu-west-1.amazonaws.com/2929219-min.jpg")
    no-repeat center center;
  background-size: cover;
}
@media only screen and (max-width: 600px) {
  div#img-background {
    width: 700px !important;
    height: 900px !important;
  }
}

.padding-login {
  padding: 0 !important;
}

#errorRow {
  min-height: 89px;
  margin-top: 2%;
  animation: mymove 5s;
  animation-delay: 2s;
  animation-name: show-soft;
}

#divLogo {
  min-height: 140px;
  min-width: 230px;
  background-image: url("https://harunnaapp89ba092d890f4a839722aea67392711c140000-dev.s3-eu-west-1.amazonaws.com/harruna-logo-fondo-blanco.jpeg");
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  margin: 10px;
}

@keyframes show-soft {
  from {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

.loginForm {
  background-color: rgba(70, 58, 91, 0.2);
  border: solid;
  padding: 15px;
  border-radius: 25px;
  border-color: rgba(103, 58, 91, 0.1);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
