.image {
  background: url("../img/services1.jpg") no-repeat center center;
  background-size: cover;
  height: 40vh;
  width: 100%;
}
.services > li {
  padding-bottom: 2ex !important;
}
.fila-services > div {
  padding-top: 2em;
  padding-bottom: 2em;
}
.body-services {
  padding-top: 0 !important;
}
.container-imagen-services {
  justify-content: center;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media (max-width: 575px) {
  .fila-services .card img {
    height: 10vh !important;
    width: 25vw !important;
  }
}
.fila-services .card img {
  width: 10vw;
}
.fila-services .card {
  height: auto !important;
}
.fila-services .card-footer {
  background-color: #dabb68 !important;
}
.fila-services .card-header {
  background-color: #dabb68 !important;
  font-weight: bold;
  color: white;
}
.letra-services {
  color: white;
}
.fila-services .card-footer .btn-outline-info {
  color: white;
  border: none !important;
}
.fila-services .card-footer .btn-outline-info:hover {
  background-color: black;
  border: none !important;
  box-shadow: 0 0 0 0.2rem rgba(2, 12, 14, 0.5) !important;
}
.fila-services .card-footer .btn-outline-info:active {
  background-color: black !important;
  border: none !important;
}

.fila-services .card-footer .btn-outline-info:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 12, 14, 0.5) !important;
}
