.button-cancel-margin {
  margin-top: 6%;
}

.button-right {
  padding-right: 2em !important;
}
.button-left {
  padding-right: 2em !important;
}

.nav-home {
  padding-top: 0 !important;
}

.dropdown > a {
  color: white;
}

.dropdown > a:hover {
  color: rgb(200, 200, 200);
}

.App {
  text-align: center;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
