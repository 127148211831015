.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.marketing .title {
  margin-bottom: 2em;
  margin-top: 1em;
  /* background-color: rgb(231, 231, 231); */
}

.marketing {
  text-align: center;
}

.img-highlighted {
  height: 235px !important;
}

.App-logo {
  height: 6vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.texto-destacados > .btn-secondary {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
}
.texto-destacados > .btn-secondary:hover {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8);
}
.texto-destacados > .btn-secondary:hover,
.btn-secondary:focus {
  color: black !important;
  background-color: #edd691 !important;
  border-color: #edd691 !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8) !important;
}

.texto-destacados > .btn-secondary:not(:disabled):not(.disabled):active {
  color: black;
  background-color: #edd691;
  border-color: #edd691;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 38, 0.8);
}
